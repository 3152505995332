export default {
  data() {
    return {
      filterData: null,
      currentPage: null,
      totalPage: null
    };
  },
  computed: {
    selectedItems() {
      let items = [];
      for (const key1 in this.filterData) {
        if (Object.hasOwnProperty.call(this.filterData, key1)) {
          const element = this.filterData[key1];
          for (const key2 in element.options) {
            if (Object.hasOwnProperty.call(element.options, key2)) {
              const option = element.options[key2];
              if (option.checked)
                items.push({
                  ...option,
                  key1,
                  key2,
                  groupName: element.slug
                });
            }
          }
        }
      }
      return items;
    },
    pagiData() {
      if (this.currentPage) {
        let p = [];
        if (this.currentPage > 1) p.push({ type: "prev", s: true });
        else p.push({ type: "prev", s: false });
        let pre = this.currentPage;
        if (pre > 4) {
          p.push({ type: "page", l: 1 });
          p.push({ type: "dot" });
          for (let i = this.currentPage - 2; i < this.currentPage; i++) {
            p.push({ type: "page", l: i, current: false });
          }
        } else {
          for (let i = 1; i < this.currentPage; i++) {
            p.push({ type: "page", l: i, current: false });
          }
        }
        let post = this.totalPage - this.currentPage;
        if (post > 4) {
          for (let i = this.currentPage; i < this.currentPage + 3; i++) {
            if (i == this.currentPage)
              p.push({ type: "page", l: i, current: true });
            else p.push({ type: "page", l: i, current: false });
          }
          p.push({ type: "dot" });
          p.push({ type: "page", l: this.totalPage });
        } else {
          for (let i = this.currentPage; i <= this.totalPage; i++) {
            if (i == this.currentPage)
              p.push({ type: "page", l: i, current: true });
            else p.push({ type: "page", l: i, current: false });
          }
        }
        if (this.currentPage < this.totalPage)
          p.push({ type: "next", s: true });
        else p.push({ type: "next", s: false });
        return p;
      }
      return null;
    }
  },
  methods: {
    onPagiClick(p) {
      switch (p) {
        case "prev":
          this.currentPage--;
          this.sendQuery();
          break;
        case "next":
          this.currentPage++;
          this.sendQuery();
          break;
        default:
          this.currentPage = p;
          this.sendQuery();
          break;
      }
    },
    onFilterChange(e) {
      if (this.filterData[e.key1].options[e.key2].checked != undefined) {
        this.filterData[e.key1].options[e.key2].checked =
          !this.filterData[e.key1].options[e.key2].checked;
      } else {
        this.filterData[e.key1].options[e.key2].checked = true;
      }
      this.$set(this.filterData, e.key1, { ...this.filterData[e.key1] });
      this.sendQuery();
    },
    onFilterDelete(e) {
      this.filterData[e.key1].options[e.key2].checked = false;
      let a = JSON.stringify(this.filterData);
      this.$set(this, "filterData", JSON.parse(a));
      this.sendQuery();
    },
    onClearAll() {
      for (const key1 in this.filterData) {
        if (Object.hasOwnProperty.call(this.filterData, key1)) {
          const element = this.filterData[key1];
          for (const key2 in element.options) {
            if (Object.hasOwnProperty.call(element.options, key2)) {
              element.options[key2].checked = false;
            }
          }
        }
      }
      let a = JSON.stringify(this.filterData);
      this.$set(this, "filterData", JSON.parse(a));
      this.sendQuery();
    }
  }
};
